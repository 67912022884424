@import "~bootstrap/scss/bootstrap";

.pio-vertical-color-line{
    border-left-width: 0.25rem !important;
}
.pio-border-left-danger{
    border-left-color: #dc3545 !important;
}
.pio-border-left-warning{
    border-left-color: #ffc107 !important;
}
.pio-border-left-success{
    border-left-color: #28a745 !important;
}
.pio-border-left-primary{
    border-left-color: #007bff !important;
}
.my-strikethrough{
    text-decoration: line-through;
}

html {
  position: relative;
  min-height: 100%; }

body {
    height: 100%; 
}
 
    .dark {
        background-color: black;
        color: #e6e6e6;
     
        header.mps-header {

            .head_nav {
                background-color: #222;
                color: white;
                border-bottom: 1px solid white;
                
                button {
                    color: white;
                }
            }
            
            .logo_search_content {

               .logo {
                    background: url('../images/mps-logo-white@2x.png') no-repeat;
                    @media only screen and (min-width: 980px){
                        background: url('../images/mps-logo-white.png') no-repeat;
                    }
                }             

                .search form {
                    border: 1px solid white;
                    input {
                        background-color: black;
                        color: white;
                    }
                }
            } 
            
            nav.topnav, 
            nav[role=navigation] {
                background: white;
                color: black;
            }
            
            nav.topnav {
                ul {
                    li {
                        a {
                            color: black;
                        }
                    }
                }
            }

        }       

            .breadcrumbs {
                a {
                    color: white;
                }
            } 
            
            .content {
                border-top: 2px solid white;
                color: white;
                

            }
                  
            .btn-success,
            .btn-primary {
                color: black;
                background-color: white;
                border-color: black;
            }          
  
            .btn-secondary {
                color: #fff;
                background-color: black;
                border-color: #fff;
            }
     
            .page-item {
                .page-link {
                    color: black;
                    background: white;
                }                
                .active {
                    .page-link {
                        color: white;
                        background-color: black;
                        border-color: black;
                    }    
                }
              
            }
            
        .modal-content {
            border-radius: 0;
            background-color: #222;
            a {
                color: white;
                text-decoration: underline;
                &:hover{
                    color: white;
                    border: none;
                }
            }
            ul li::marker {
                color: white;
            }
        }
        
        
        footer.mps-footer {
            .footer_bottom {
                background: black;
                a {
                    color: white;
                }
                h2 {
                    color: white;
                }
            }
            .footer_gov {
                background:black;
                color: white;
            }
            
        }
        
        
    }

    #wrapper {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex; 
        #content-wrapper {
            overflow-x: hidden;
            width: 100%;
        }
        .container-fluid  {
            padding-top: 1rem;
            padding-bottom: 80px; 
        }
    }

    
    header.mps-header {
        border-bottom: 2px solid #e9e9e9;
        margin-bottom: 20px;
        position: relative;
  
        @media only screen and (min-width: 760px) {
            border: 0;
            margin-bottom: 20px;
            padding: 0;
        }
        
        .head_nav {
            background: #eee;
            color: #424242;
            padding: 10px 0;
            overflow: hidden;
            a {
                color: #424242;
            }
            
            .logoSmall {
                float: left;
                width: 120px;
                @media only screen and (min-width: 760px){
                    width: 171px;
                }
                height: 27px;
                background: url('../images/govhr_logo_white.png') no-repeat 0 0;
            }
            
            .impaired {
                button.visual {
                    align-items: flex-start;
                    text-align: center;
                    cursor: default;
                    background-color: transparent;
                    border-color: transparent;
                    box-sizing: border-box;
                    border-width: 0;
                    border-style: none;
                    border-image: none;
                    &:hover {
                        color: #191919;
                        text-decoration: underline;
                        cursor: pointer;
                    }
                    &:focus {
                        outline: none;
                    }
                }
                span {
                    float: right;
                }
                
                .change {
                    cursor: pointer;
                    padding: 3px;
            //        display: block;
                    font-size: 14px;
                    @media only screen and (min-width: 760px){
                        font-size: 16px;
                    }
                }
                
                button {
                    border: 0;
                    background-color: transparent;
                    &:focus {
                        border: 0;
                        outline: none;
                    }
                    :focus-visible {
                        outline: none;
                    }
                }

                #normal {
                    margin-left: 10px;
                    font-size: 14px!important;
                } 
                #increase {
                    @media only screen and (min-width: 760px){
                        font-size: 18px!important;
                    }
                } 
                
                .acessibilidade {
                    font-size: inherit;
                }
                
            }
  
        }  
    
        .logo_search_content {
            padding: 20px 0;
            overflow: hidden;
            
            .logo {
                display: block;
                background: url('../images/mps-logo@2x.png') no-repeat;
                background-size: auto 55px; 
                margin-top: 2px; 
                height: 55px;
                margin-bottom: 15px;
                @media only screen and (min-width: 760px){
                    float: left;
                    width: 50%;
                    margin-bottom: 0;
                }
                @media only screen and (min-width: 980px){
                    background: url('../images/mps-logo.png') no-repeat;
                    height: 72px;
                    background-size: auto 72px !important;
                }
                
            }
    
            .topnav_icon {
                float: right;
                    @media only screen and (min-width: 760px){
                        display: none;
                    }
                a {
                    display: block;
                    background: #e9e9e9 url('../images/icon_menu@2x.png') no-repeat center;
                    background-size: 35px 24px !important;
                    width: 60px;
                    height: 45px;
                    text-decoration: none;       
                }
            }
            
            .search {
                margin-right: 80px;
                clear: left;
                @media only screen and (min-width: 760px){
                    width: 50%;
                    clear: none;
                    margin: 7px 0 0 0;
                    float: right;  
                }
                @media only screen and (min-width: 980px){
                    width: 460px;
                    margin-top: 15px;
                }                
                @media only screen and (min-width: 1160px){
                    width: 560px;
                }               

                form {
                    border: 1px solid #191919;
                    height: 45px;
                    padding: 5px 5px 0 5px;
                    @media only screen and (min-width: 760px){
                        float: right;
                        width: 348px;
                    }
                    @media only screen and (min-width: 980px){
                        width: 448px;
                    }  
                    @media only screen and (min-width: 1160px){
                        width: 548px;
                    } 
                    
                    input.search_input {
                        border: 0;
                        float: left;
                        padding: 6px 5px 5px 10px;
                        width: 150px;
                        @media only screen and (min-width: 768px){
                            width: 290px;
                        }
                        @media only screen and (min-width: 980px){
                            width: 390px;
                        }                         
                        @media only screen and (min-width: 1160px){
                            width: 490px;
                        } 
                    }
                    .search_btn {
                        float: right;
                        border: 0;
                        background: url('../images/icon_search@2x.png') no-repeat center;
                        background-size: 21px 21px !important;
                        width: 21px;
                        height: 21px;
                        margin: 6px 3px 0 0;
                        @media only screen and (min-width: 760px){
                            background: url('../images/icon_search.png') no-repeat center;
                            width: 34px;
                            height: 32px;
                            margin: 0;
                        } 
                    }                         
                }           
                
            }       

        }
        
        
        nav.topnav, nav[role=navigation] {
            background: #033e7c;
        }
        
        nav.topnav {
            display: none;
            position: absolute;
            top: 83px;
            left: 0;
            width: 100%;
            line-height: 26px;
            padding: 10px 0;
            z-index: 999; 
           @media only screen and (min-width: 760px){
              display: block !important;
                position: relative;
                line-height: 26px;
                padding: 10px 0 10px 22px;
                top: 0;
                width: auto; 
           }
           @media only screen and (min-width: 760px){
                height: 44px;
                line-height: 46px;
                padding: 0 0 0 22px;               
           }
            
            ul, ol {
                list-style: none;
                list-style-image: none;
            }  
            ul {
                margin: 0;
                padding: 0;
                
                li {
                    float: left;
                    padding: 0;
                    margin-right: 22px;
                    
                    a {
                        color: #FFF;
                        text-decoration: none;
                    }
                }

                
            } 
            

 
        }       

    }

    .breadcrumbs {

        ul {
            list-style: none;
            margin: 0;
            padding: 0;
            li {
                display: inline-block;
                padding: 0 10px 0 12px;
                border-left: 1px solid #787878;
                position: relative;
                &:first-child {
                    border: 0;
                    padding-left: 0;
                }

            }
        }

        a {
            text-decoration: none;
            color: #424242;
            &.active {
                font-weight: 600;
            }            
        }   
        
        @media (max-width: 980px) {
            margin-bottom: 1rem;
        }
    
        @media (max-width: 480px) {
            ul {
                text-align: center;
                li {
                    border-left: none;
                    margin-bottom: 0.5rem;
                }
            }
        }
        
    }    
    
    .btn {
        border-radius: 0;
    }
    
    .content {
        border-top: 2px solid #033e7c;
        padding-top: 12px;
        margin-top: 20px;
        color: #191919;  
        
        h1 {
            font-family: Times;
            font-size: 35px;
            margin-top: 1rem;
            margin-bottom: 1rem;
        }
        
        .header-title {
            text-align: center;
            @media only screen and (min-width: 760px){
                text-align: left;
            }
            .btn {
                margin-bottom: 15px;
            }
        }
        
        .form-control, 
        .pagination, 
        .btn {
            border-radius: 0;
        }
        .select2-container--default .select2-selection--multiple {
            border: 1px solid #ced4da;
            border-radius: 0;
        }
        
        .page-item {
            &:first-child .page-link {
                border-top-left-radius: 0;
                border-bottom-left-radius: 0;                
            }            
            &:last-child .page-link {
                border-top-right-radius: 0;
                border-bottom-right-radius: 0;                
            }
        }
        
             .card {
                border-radius: 0;
            }
        
    
    }
    

    footer.mps-footer {

        .footer_bottom {
            background: #eee;
            padding: 24px 0;
            color: #424242;
            a {
                color: #191919;
                &:hover {
                    text-decoration: underline;
                }
            }
            h2 {
                font-family: Times; 
                font-size: 24px;                
                color: #424242;
            }

            ul {
                list-style: none;
                margin: 0;
                padding: 0;
            }   
        
            .footer_col1, .footer_col2, .footer_col3 {
                margin-bottom: 20px;
            }
            
            
        }

        .footer_gov {
            background: #033e7c;
            color: #FFF;
            font-size: 14px;
            line-height: 18px;
            padding: 12px 0;

            a {
                color: #FFFFFF;
                &:hover {
                    color: #DDDDDD;
                }
            }

            .footer_vrh {
                float: left;
                a {
                    display: block;
                    color: #FFF;
                    background: url('../images/arrow_up.png') no-repeat left center;
                    padding-left: 18px;
                }
            }
            
            .footer_cp {
                
            }            
            
            .footer_col4 {
                
                @media only screen and (min-width: 760px) { 
                    float: right;
                }
                
                nav {
                    @media only screen and (min-width: 760px) { 
                        float: right;
                    }
                    ul, ol {
                        list-style: none;
                        list-style-image: none;
                    }
                }
                li {
                    float: left;
                    margin: 0 0 0 10px;
                    a {
                        display: block;
                        line-height: 1px;      
                    }
                    span {
                        display: inline-block;
                        vertical-align: middle;
                        margin-right: 10px;
                        cursor: pointer;    
                        width: 18px;
                        height: 18px;                      
                    }
                }
                .fb_icon {
                    background-image: url('../images/icon_facebook@2x.png');
                    background-size: 18px 18px !important;
                    @media only screen and (min-width: 760px){
                        background-image: url('../images/icon_facebook.png');
                    } 
                }
                .yt_icon {
                    background-image: url('../images/icon_youtube@2x.png');
                    background-size: 18px 18px !important;
                    @media only screen and (min-width: 760px){
                        background-image: url('../images/icon_youtube.png');
                    } 
                }
            }
            
        }

    }
    
    .modal-content {
        border-radius: 0;
        .close-modal {
            position: absolute;
            top: 1rem;
            right: 1rem;
            cursor: pointer;
            background-color: transparent;
        }
        .modal-body {
            
            p {
                word-break: break-word;
            }
            
            .q-details-question {
                font-family: Times;
                font-size: 21px;
                line-height: 1.2;
            }            
        }
    }


    // DataTables
div.dataTables_length {
  float: left;
}

div.dataTables_filter {
    float: right;
}

div.dt-buttons {
    float: right;
    margin-left: 1rem;
}
    

.ellipsis3 {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}


.scroll-to-top {
  position: fixed;
  right: 15px;
  bottom: 15px;
  display: none;
  width: 50px;
  height: 50px;
  text-align: center;
  color: #fff;
  background: rgba(52, 58, 64, 0.5);
  line-height: 46px; }
  .scroll-to-top:focus, .scroll-to-top:hover {
    color: white; }
  .scroll-to-top:hover {
    background: #343a40; }
  .scroll-to-top i {
    font-weight: 800; }

input.largerCheckbox {
  width: 40px;
  height: 40px; }

.smaller {
  font-size: 0.7rem; }

.o-hidden {
  overflow: hidden !important; }

.z-0 {
  z-index: 0; }

.z-1 {
  z-index: 1; }

.navbar-nav .form-inline .input-group {
  width: 100%; }

.navbar-nav .nav-item.active .nav-link {
  color: #fff; }

.navbar-nav .nav-item.dropdown .dropdown-toggle::after {
  width: 1rem;
  text-align: center;
  float: right;
  vertical-align: 0;
  border: 0;
  font-weight: 900;
  content: '\f105';
  font-family: 'Font Awesome 5 Free'; }

.navbar-nav .nav-item.dropdown.show .dropdown-toggle::after {
  content: '\f107'; }

.navbar-nav .nav-item.dropdown.no-arrow .dropdown-toggle::after {
  display: none; }

.navbar-nav .nav-item .nav-link:focus {
  outline: none; }

.navbar-nav .nav-item .nav-link .badge {
  position: absolute;
  margin-left: 0.75rem;
  top: 0.3rem;
  font-weight: 400;
  font-size: 0.5rem; }

@media (min-width: 768px) {
  .navbar-nav .form-inline .input-group {
    width: auto; } }

.sidebar {
  width: 90px !important;
  background-color: #212529;
  min-height: calc(100vh - 56px); }
  .sidebar .nav-item:last-child {
    margin-bottom: 1rem; }
  .sidebar .nav-item .nav-link {
    text-align: center;
    padding: 0.75rem 1rem;
    width: 90px; }
    .sidebar .nav-item .nav-link span {
      font-size: 0.65rem;
      display: block; }
  .sidebar .nav-item .dropdown-menu {
    position: absolute !important;
    -webkit-transform: none !important;
    -ms-transform: none !important;
    transform: none !important;
    left: calc(90px + 0.5rem) !important;
    margin: 0; }
    .sidebar .nav-item .dropdown-menu.dropup {
      bottom: 0;
      top: auto !important; }
  .sidebar .nav-item.dropdown .dropdown-toggle::after {
    display: none; }
  .sidebar .nav-item .nav-link {
    color: rgba(255, 255, 255, 0.5); }
    .sidebar .nav-item .nav-link:active, .sidebar .nav-item .nav-link:focus, .sidebar .nav-item .nav-link:hover {
      color: rgba(255, 255, 255, 0.75); }
  .sidebar.toggled {
    width: 0 !important;
    overflow: hidden; }

@media (min-width: 768px) {
  .sidebar {
    width: 225px !important; }
    .sidebar .nav-item .nav-link {
      display: block;
      width: 100%;
      text-align: left;
      padding: 1rem;
      width: 225px; }
      .sidebar .nav-item .nav-link span {
        font-size: 1rem;
        display: inline; }
    .sidebar .nav-item .dropdown-menu {
      position: static !important;
      margin: 0 1rem;
      top: 0; }
    .sidebar .nav-item.dropdown .dropdown-toggle::after {
      display: block; }
    .sidebar.toggled {
      overflow: visible;
      width: 90px !important; }
      .sidebar.toggled .nav-item:last-child {
        margin-bottom: 1rem; }
      .sidebar.toggled .nav-item .nav-link {
        text-align: center;
        padding: 0.75rem 1rem;
        width: 90px; }
        .sidebar.toggled .nav-item .nav-link span {
          font-size: 0.65rem;
          display: block; }
      .sidebar.toggled .nav-item .dropdown-menu {
        position: absolute !important;
        -webkit-transform: none !important;
        -ms-transform: none !important;
        transform: none !important;
        left: calc(90px + 0.5rem) !important;
        margin: 0; }
        .sidebar.toggled .nav-item .dropdown-menu.dropup {
          bottom: 0;
          top: auto !important; }
      .sidebar.toggled .nav-item.dropdown .dropdown-toggle::after {
        display: none; } }

.card-body-icon {
  position: absolute;
  z-index: 0;
  top: -1.25rem;
  right: -1rem;
  opacity: 0.4;
  font-size: 5rem;
  -webkit-transform: rotate(15deg);
  -ms-transform: rotate(15deg);
  transform: rotate(15deg); }

@media (min-width: 576px) {
  .card-columns {
    -webkit-column-count: 1;
    column-count: 1; } }

@media (min-width: 768px) {
  .card-columns {
    -webkit-column-count: 2;
    column-count: 2; } }

@media (min-width: 1200px) {
    .container {
        max-width: 1160px;
    } 
  .card-columns {
    -webkit-column-count: 2;
    column-count: 2; } }

:root {
  --input-padding-x: 0.75rem;
 // --input-padding-y: 0.75rem; }

.card-login {
  max-width: 25rem; }

.card-register {
  max-width: 40rem; }

.form-label-group {
  position: relative; }

.form-label-group > input,
.form-label-group > label {
  padding: var(--input-padding-y) var(--input-padding-x);
  height: auto; }

.form-label-group > label {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  margin-bottom: 0;
  /* Override default `<label>` margin */
  line-height: 1.5;
  color: #495057;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  -webkit-transition: all 0.1s ease-in-out;
  -o-transition: all 0.1s ease-in-out;
  transition: all 0.1s ease-in-out; }

.form-label-group input::-webkit-input-placeholder {
  color: transparent; }

.form-label-group input:-ms-input-placeholder {
  color: transparent; }

.form-label-group input::-ms-input-placeholder {
  color: transparent; }

.form-label-group input::-moz-placeholder {
  color: transparent; }

.form-label-group input::placeholder {
  color: transparent; }

.form-label-group input:not(:placeholder-shown) {
  padding-top: calc(var(--input-padding-y) + var(--input-padding-y) * (2 / 3));
  padding-bottom: calc(var(--input-padding-y) / 3); }

.form-label-group input:not(:placeholder-shown) ~ label {
  padding-top: calc(var(--input-padding-y) / 3);
  padding-bottom: calc(var(--input-padding-y) / 3);
  font-size: 12px;
  color: #777; }

footer.sticky-footer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: absolute;
  right: 0;
  bottom: 0;
  width: calc(100% - 90px);
  height: 80px;
  background-color: #e9ecef; }
  footer.sticky-footer .copyright {
    line-height: 1;
    font-size: 0.8rem; }
  @media (min-width: 768px) {
    footer.sticky-footer {
      width: calc(100% - 225px); } }

body.sidebar-toggled footer.sticky-footer {
  width: 100%; }

@media (min-width: 768px) {
  body.sidebar-toggled footer.sticky-footer {
    width: calc(100% - 90px); 
  } 
}} 





/* Print Modal */
@media print {
    

    .sidebar, print {
        display: none;
    }
    
    body.modalprinter * {
        visibility: hidden;
    }
    
    body.modalprinter {
        
        .modal-dialog {
            max-width: 100%;
            width: 100%;
            
            .modal-content {      
                height: 100%;
                border-radius: 0;
                overflow:auto;
            }          
            
            
        }  
        
        .modal-dialog.focused {
            position: absolute;
            padding: 0;
            margin: 0;
            left: 0;
            top: 0;
        }

        .modal-dialog.focused .modal-content {
            border-width: 0;
        }

        .modal-dialog.focused .modal-content .modal-header .modal-title,
        .modal-dialog.focused .modal-content .modal-header .modal-title span,
        .modal-dialog.focused .modal-content .modal-body,
        .modal-dialog.focused .modal-content .modal-body * {
            visibility: visible;
        }

        .modal-dialog.focused .modal-content .modal-header,
        .modal-dialog.focused .modal-content .modal-body {
            padding: 0;
        }

        .modal-dialog.focused .modal-content .modal-header .modal-title {
            margin-bottom: 20px;
        }
    }

}